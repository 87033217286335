import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

type Props = {
  icon: ReactElement;
  selected: string | boolean;
  label: string;
  onClick?: () => void;
};

const LinkListItem = ({ icon, selected, label, onClick = () => {} }: Props) => {
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <ListItem disablePadding>
      {typeof selected === 'string' ? (
        <ListItemButton component={NavLink} selected={location.pathname.includes(selected)} to={selected}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={t(label)} />
        </ListItemButton>
      ) : (
        <ListItemButton onClick={onClick} selected={selected}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={t(label)} />
        </ListItemButton>
      )}
    </ListItem>
  );
};

export default LinkListItem;

import { GetTitleMetadataResult } from '@amzn/ce-browse-models';
import { useQuery } from '@apollo/client';

import getTitleMetadata from '../graphql/GetTitleMetadata';

// Placeholder for now. Should be done by https://originalsaccess.atlassian.net/browse/MSC-1837
const useGetTitleMetadata = (titleId?: string | null) => {
  const query = useQuery<GetTitleMetadataResult>(getTitleMetadata, {
    variables: { input: { titleId } },
    errorPolicy: 'all',
    skip: !titleId,
  });

  return query;
};

export default useGetTitleMetadata;

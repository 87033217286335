import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import React, { forwardRef } from 'react';

type Props = {
  paths: Array<string>;
  maxItems?: number;
};

const FolderBreadcrumb = forwardRef<HTMLElement, Props>(({ paths, maxItems = 8 }, ref) => (
  <Box ref={ref} mb={1}>
    <Breadcrumbs aria-label="breadcrumb" maxItems={maxItems} separator="/">
      {paths.splice(0, paths.length - 1).map((path, index) => (
        <Link
          href="/" // This should be updated later
          // eslint-disable-next-line react/no-array-index-key
          key={`${path}-${index}`}
          variant="body1"
          color="text.secondary">
          {path}
        </Link>
      ))}
      <Typography variant="body1" color="text.primary">
        {paths[paths.length - 1]}
      </Typography>
    </Breadcrumbs>
  </Box>
));

FolderBreadcrumb.displayName = 'FolderBreadcrumb';

export default FolderBreadcrumb;

import { SearchEmployeeFilterOptionType } from '../enums/SearchEmployeeFilterOptionType';

export const SearchEmployeesFilterOptions = [
  {
    // This will filter results to only people with leaderId in their management chain
    type: SearchEmployeeFilterOptionType.LeaderIdInManagementChain,
    // Limit the search under Mike Hopkins' org(https://phonetool.amazon.com/users/mikehopk)
    value: '106149343', // Mike Hopkins' leader id
  },
];

import { NotFoundError } from '@amzn/sitc-frontend/components';
import React from 'react';
import { Navigate, Route, Routes as BrowseRoutes, useNavigate } from 'react-router-dom';

import pathManifest from '../constants/pathManifest';
import AssetSpecs from './admin/AssetSpecs';
import DistributionProfiles from './admin/DistributionProfiles';
import IngestProfiles from './admin/IngestProfiles';
import Archival from './archival/Archival';
import MyItems from './archival/MyItems';
import AssetDetail from './asset-library/AssetDetail';
import Assets from './asset-library/Assets';
import TitleDetail from './asset-library/TitleDetail';
import Titles from './asset-library/Titles';
import Unmatched from './asset-library/Unmatched';

const Routes = () => {
  const navigate = useNavigate();

  return (
    <BrowseRoutes>
      {/* Home */}
      <Route element={<Navigate to={pathManifest.home} />} path="/" />

      {/* Asset Library */}
      <Route element={<Titles />} path={pathManifest.assetLibrary.titles.index} />
      <Route element={<TitleDetail />} path={pathManifest.assetLibrary.titles.detail} />
      <Route element={<Assets />} path={pathManifest.assetLibrary.assets.index} />
      <Route element={<AssetDetail />} path={pathManifest.assetLibrary.assets.detail} />
      <Route element={<Unmatched />} path={pathManifest.assetLibrary.unmatched} />
      <Route element={<Navigate to={pathManifest.assetLibrary.titles.index} />} path={pathManifest.assetLibrary.index} />

      {/* Archival */}
      <Route element={<Archival />} path={pathManifest.archival.archival} />
      <Route element={<MyItems />} path={pathManifest.archival.myItems} />
      <Route element={<Navigate to={pathManifest.archival.archival} />} path={pathManifest.archival.index} />

      {/* Admin */}
      <Route element={<IngestProfiles />} path={pathManifest.admin.ingest.index} />
      <Route element={<IngestProfiles />} path={pathManifest.admin.ingest.detail} />
      <Route element={<IngestProfiles />} path={pathManifest.admin.ingest.create} />
      <Route element={<IngestProfiles />} path={pathManifest.admin.ingest.edit} />

      <Route element={<DistributionProfiles />} path={pathManifest.admin.distribution.index} />
      <Route element={<DistributionProfiles />} path={pathManifest.admin.distribution.detail} />
      <Route element={<DistributionProfiles />} path={pathManifest.admin.distribution.create} />
      <Route element={<DistributionProfiles />} path={pathManifest.admin.distribution.edit} />
      <Route element={<AssetSpecs />} path={pathManifest.admin.assetSpecs.index} />
      <Route element={<Navigate to={pathManifest.admin.ingest.index} />} path={pathManifest.admin.index} />

      {/* Misc */}
      <Route
        element={<NotFoundError actionLabel="Go to Homepage" onAction={() => navigate(pathManifest.assetLibrary.titles.index)} />}
        path="*"
      />
    </BrowseRoutes>
  );
};

export default Routes;

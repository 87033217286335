import ArchiveOutlined from '@mui/icons-material/ArchiveOutlined';
import BookmarkOutlined from '@mui/icons-material/BookmarkOutlined';
import DownloadOutlined from '@mui/icons-material/DownloadOutlined';
import InsertDriveFileOutlined from '@mui/icons-material/InsertDriveFileOutlined';
import MovieCreationOutlinedIcon from '@mui/icons-material/MovieCreationOutlined';
import PhotoLibraryOutlined from '@mui/icons-material/PhotoLibraryOutlined';
import QuestionMarkOutlined from '@mui/icons-material/QuestionMarkOutlined';
import UploadOutlined from '@mui/icons-material/UploadOutlined';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import pathManifest from '../../../constants/pathManifest';
import { AdminList, ArchivalList, AssetLibraryList, Tab } from '../../../enums/Tab';
import LinkListItem from './LinkListItem';

type Props = {
  tab: Tab;
};

export const Lists = {
  [Tab.AssetLibrary]: {
    [AssetLibraryList.Titles]: {
      icon: <MovieCreationOutlinedIcon />,
      label: 'sam.assetlibrary.menu.titles',
      selected: pathManifest.assetLibrary.titles.index,
    },
    [AssetLibraryList.Assets]: {
      icon: <PhotoLibraryOutlined />,
      label: 'sam.assetlibrary.menu.assets',
      selected: pathManifest.assetLibrary.assets.index,
    },
    [AssetLibraryList.Unmatched]: {
      icon: <QuestionMarkOutlined />,
      label: 'sam.assetlibrary.menu.unmatched',
      selected: pathManifest.assetLibrary.unmatched,
    },
  },
  [Tab.Archival]: {
    [ArchivalList.Archival]: {
      icon: <ArchiveOutlined />,
      label: 'sam.archival.menu.archival',
      selected: pathManifest.archival.archival,
    },
    [ArchivalList.MyItems]: {
      icon: <BookmarkOutlined />,
      label: 'sam.archival.menu.myitems',
      selected: pathManifest.archival.myItems,
    },
  },
  [Tab.Admin]: {
    [AdminList.Ingest]: {
      icon: <DownloadOutlined />,
      label: 'sam.admin.menu.ingestprofiles',
      selected: pathManifest.admin.ingest.index,
    },
    [AdminList.Distribution]: {
      icon: <UploadOutlined />,
      label: 'sam.admin.menu.distributionprofiles',
      selected: pathManifest.admin.distribution.index,
    },
    [AdminList.AssetSpecs]: {
      icon: <InsertDriveFileOutlined />,
      label: 'sam.admin.menu.assetspecs',
      selected: pathManifest.admin.assetSpecs.index,
    },
  },
};

const ListMenu = ({ tab }: Props) => {
  const navigate = useNavigate();

  return (
    <Box data-testid="tab-lists" py={1} mt={1}>
      <List disablePadding>
        {Object.entries(Lists[tab]).map(([key, value]) => (
          <LinkListItem
            icon={value.icon}
            key={key}
            label={value.label}
            onClick={() => {
              navigate(value.selected);
            }}
            selected={value.selected}
          />
        ))}
      </List>
      <Divider sx={{ padding: 1 }} />
    </Box>
  );
};

export default ListMenu;

import { GetProfilesInput, GetProfilesResult } from '@amzn/ce-browse-models';
import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';

import getProfiles from '../graphql/GetProfiles';

const useGetProfilesQuery = (input: GetProfilesInput, fetchPolicy: WatchQueryFetchPolicy = 'cache-first') => {
  const query = useQuery<GetProfilesResult>(getProfiles, {
    variables: { input },
    errorPolicy: 'all',
    fetchPolicy,
  });

  return query;
};

export default useGetProfilesQuery;

import { Theme } from '@mui/material/styles';

import { warning } from '../context/Theme';

const useStatusStyles = {
  dot: {
    display: 'inline-block',
    marginRight: '1ch',
    height: '0.75rem',
    width: '0.75rem',
    borderRadius: '50%',
  },
  approved: { backgroundColor: (theme: Theme) => theme.palette.success.dark },
  active: { backgroundColor: (theme: Theme) => theme.palette.success.dark },
  verified: { backgroundColor: (theme: Theme) => theme.palette.success.dark },
  inactive: { backgroundColor: (theme: Theme) => theme.palette.grey[400] },
  pending: { backgroundColor: warning },
  open: { backgroundColor: warning },
};

export default useStatusStyles;

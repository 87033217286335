import { SAMAsset } from '@amzn/ce-browse-models';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import React, { useState } from 'react';

import Table from '../common/table/Table';
import { columns } from './table/AssetsTableColumns';

type Props = {
  data: Array<SAMAsset>;
};

// Placeholder
const UnmatchedMain = ({ data }: Props) => {
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const onRowSelectionModelChange = (selection: GridRowSelectionModel) => {
    setRowSelectionModel(selection);
  };
  return <Table data={data} columns={columns} currentSelection={rowSelectionModel} onRowSelectionModelChange={onRowSelectionModelChange} />;
};

export default UnmatchedMain;

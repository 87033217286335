import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import pathManifest from '../../constants/pathManifest';
import CloseBtn from '../common/buttons/CloseBtn';

type Props = {
  onClose: () => void;
  titleId?: string | null;
};

const TitlesPreview = ({ onClose, titleId }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Paper elevation={2} sx={{ borderRadius: 0 }}>
      <Stack spacing={2} justifyContent="space-between" sx={{ minHeight: '100vh' }}>
        <Stack p={3} direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle1">{t('Series Overview')}</Typography>
          <CloseBtn onClose={onClose} />
        </Stack>

        <Stack p={3} direction="row" justifyContent="space-between" alignItems="center">
          <Button
            sx={{ width: '100%' }}
            variant="contained"
            onClick={() => {
              if (titleId) {
                navigate(`${pathManifest.assetLibrary.titles.index}/${titleId}`);
              }
            }}>
            {t('Series Details')}
            <ArrowForwardIcon />
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default TitlesPreview;

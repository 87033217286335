import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import { SxProps, Theme } from '@mui/material/styles';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  title?: string | null;
  onSubmit?: () => void;
  paperStyles?: SxProps<Theme>;
  inputStyles?: SxProps<Theme>;
};

const SearchBar = ({ title, onSubmit, paperStyles, inputStyles }: Props) => {
  const [inputValue, setInputValue] = useState('');
  const { t } = useTranslation();

  const handleChange = (event: ChangeEvent) => {
    setInputValue((event.target as HTMLInputElement).value);
  };

  return (
    <Paper
      component="form"
      onSubmit={onSubmit}
      sx={{ m: '8px 0px', p: '2px', display: 'flex', alignItems: 'center', width: 300, borderRadius: '6px', ...paperStyles }}>
      <IconButton aria-label="search">
        <SearchIcon />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1, background: 'transparent', ...inputStyles }}
        placeholder={title ? t(`Search in ${title}`) : t('Search')}
        inputProps={{ 'aria-label': 'search', 'data-testid': 'search-bar-input' }}
        value={inputValue}
        onChange={handleChange}
      />
    </Paper>
  );
};

export default SearchBar;

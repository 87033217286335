const DefaultExpiryTime = 17280000000; // 200 days

export const setLocalStorageItemWithExpiry = (key: string, value: string, ttl: number = DefaultExpiryTime): void => {
  const now = new Date();

  /* `item` is an object which contains the original value
     as well as the time when it's supposed to expire */

  const item = {
    value,
    expiry: now.getTime() + ttl,
  };

  localStorage.setItem(key, JSON.stringify(item));
};

export const getLocalStorageItemWithExpiry = (key: string) => {
  const itemStr = localStorage.getItem(key);

  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  /* compare the expiry time of the item with the current time
     if the item is expired, delete the item from storage and return null */

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return item.value;
};

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { GridColDef } from '@mui/x-data-grid-premium';
import React from 'react';
import { TFunction } from 'react-i18next';

import pathManifest from '../../../constants/pathManifest';
import { AssetsTableColumn } from '../../../enums/AssetsTableColumn';
import AssetOrderStatus from '../../common/AssetOrderStatus';
import AssetTypeIcon from '../../common/icons/AssetTypeIcon';
import PhoneToolLink from '../../common/PhoneToolLink';
import Status from '../../common/Status';

const assetsTableColumns = (intl: TFunction<'translation', undefined>): Array<GridColDef> => [
  {
    field: AssetsTableColumn.FileName,
    renderHeader: () => intl('File Name'),
    renderCell: ({ row }) => (
      <>
        <AssetTypeIcon assetType={(row.assetSpecType as string).toLowerCase()} isInverted />
        <Typography>{row.fileName}</Typography>
      </>
    ),
    minWidth: 300,
  },
  {
    field: AssetsTableColumn.AssetSpecName,
    renderHeader: () => intl('Asset Spec'),
  },
  {
    field: AssetsTableColumn.Version,
    renderHeader: () => intl('Version'),
    valueGetter: ({ row }) => row?.workOrder?.workOrderVersion as string,
  },
  {
    field: AssetsTableColumn.AssetStatus,
    renderHeader: () => intl('Asset Status'),
    renderCell: ({ row }) => <AssetOrderStatus status={row?.assetStatus} />,
  },
  {
    field: AssetsTableColumn.WorkOrderName,
    renderHeader: () => intl('Work Order Name'),
    valueGetter: ({ row }) => row?.workOrder?.workOrderName as string,
    minWidth: 200,
  },
  {
    field: AssetsTableColumn.AssignedVendor,
    renderHeader: () => intl('Assigned Vendor'),
    valueGetter: ({ row }) => row?.workOrder?.aassignedVendor as string,
  },
  {
    field: AssetsTableColumn.OrderStatus,
    renderHeader: () => intl('Order Status'),
    renderCell: ({ row }) => <Status status={row?.workOrder?.orderStatus} />,
  },
  {
    field: AssetsTableColumn.DateModified,
    renderHeader: () => intl('Last Modified'),
  },
  {
    field: AssetsTableColumn.ModifiedBy,
    renderHeader: () => intl('Modified By'),
    renderCell: ({ row }) => <PhoneToolLink alias={row?.modifiedBy} />,
  },
  {
    field: AssetsTableColumn.DueDate,
    renderHeader: () => intl('Due Date'),
    valueGetter: ({ row }) => row?.workOrder?.dueDate as string,
  },
  {
    field: AssetsTableColumn.TitleName,
    renderHeader: () => intl('Title Name'),
  },
  {
    field: AssetsTableColumn.IngestProfileName,
    renderHeader: () => intl('Ingest Profile'),
  },
  {
    field: AssetsTableColumn.LanguageLocale,
    renderHeader: () => intl('Language'),
  },
  {
    field: AssetsTableColumn.FileSize,
    renderHeader: () => intl('File Size'),
  },
  {
    field: AssetsTableColumn.FileExtension,
    renderHeader: () => intl('File Extension'),
  },
  {
    field: AssetsTableColumn.DistributionProfileName,
    renderHeader: () => intl('Distribution Profile'),
    minWidth: 200,
  },
  {
    field: AssetsTableColumn.ProjectName,
    renderHeader: () => intl('Project'),
    valueGetter: ({ row }) => row?.workOrder?.projectName as string,
    minWidth: 200,
  },
  {
    field: AssetsTableColumn.WorkOrderType,
    renderHeader: () => intl('Work Order Type'),
    valueGetter: ({ row }) => row?.workOrder?.workOrderType as string,
  },
  {
    field: AssetsTableColumn.DateCreated,
    renderHeader: () => intl('Date Created'),
  },
  {
    field: AssetsTableColumn.CreatedBy,
    renderHeader: () => intl('Created By'),
    renderCell: ({ row }) => <PhoneToolLink alias={row?.createdBy} />,
  },
  {
    field: AssetsTableColumn.ShowCode,
    renderHeader: () => intl('Show Code'),
    valueGetter: ({ row }) => row?.title?.showCode as string,
  },
  {
    field: AssetsTableColumn.TitleSlugId,
    renderHeader: () => intl('Title ID'),
    valueGetter: ({ row }) => row?.title?.titleSlugId as string,
  },
  {
    field: AssetsTableColumn.TitleType,
    renderHeader: () => intl('Title Type'),
    valueGetter: ({ row }) => row?.title?.titleType as string,
  },
  {
    field: AssetsTableColumn.SeasonNumber,
    renderHeader: () => intl('Season no'),
    valueGetter: ({ row }) => row?.title?.seasonNumber as string,
  },
  {
    field: AssetsTableColumn.EpisodeProductionNumber,
    renderHeader: () => intl('Ep Prod No'),
    valueGetter: ({ row }) => row?.title?.episodeProductionNumber as string,
  },
  {
    field: AssetsTableColumn.AmazonStudiosAssetId,
    renderHeader: () => intl('Asset Id'),
  },
  {
    field: AssetsTableColumn.AssetSpecType,
    renderHeader: () => intl('Asset Type'),
  },
  {
    field: AssetsTableColumn.AssetSpecSubType,
    renderHeader: () => intl('Content Type'),
  },
  {
    field: AssetsTableColumn.DirectPath,
    renderHeader: () => intl('Direct Path'),
    renderCell: ({ row }) => (
      <Link href={`${pathManifest.assetLibrary.assets.index}/${row.amazonStudiosAssetId as string}`}>{row.amazonStudiosAssetId}</Link>
    ),
    minWidth: 300,
  },
];

const baseColumnProps = {
  editable: false,
  resizable: false,
  flex: 0,
  minWidth: 150,
};

export const columns = (intl: TFunction<'translation', undefined>): Array<GridColDef> =>
  assetsTableColumns(intl).map((column) => ({ ...baseColumnProps, ...column }));

import { AuthSession } from '@amzn/sitc-frontend/contexts';
import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { Stage } from '../enums/Stage';
import { appSyncConfig } from './appSyncConfig';

export const configureApolloClient = (stage: Stage, authSession: AuthSession, appid: string) => {
  const { endpoint } = appSyncConfig[stage];

  const httpLink = createHttpLink({ uri: endpoint });

  const authLink = setContext((setter, { headers }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${authSession.idToken}`,
      appid,
    },
  }));

  const apolloClient = new ApolloClient({
    link: ApolloLink.from([authLink, httpLink]),
    cache: new InMemoryCache(),
  });

  return apolloClient;
};

import { UtilityItem } from '@amzn/sitc-frontend/types';
import { useTranslation } from 'react-i18next';

import { SupportAndFeedbackLink } from '../app';

const HeaderSupportLink = (): UtilityItem => {
  const { t } = useTranslation();

  return {
    key: 'tech-support',
    primary: t('sam.header.support'),
    iconKey: 'HelpOutline',
    toolTip: t('sam.header.support'),
    path: SupportAndFeedbackLink,
  };
};

export default HeaderSupportLink;

import { en as sitcEn } from '@amzn/sitc-frontend/lang';

export const en = {
  ...sitcEn,
  'sam.header.source': 'source',
  'sam.header.profile': 'Profile',
  'sam.header.support': 'Tech Support',
  'sam.assetlibrary.menu.titles': 'Titles',
  'sam.assetlibrary.menu.assets': 'Assets',
  'sam.assetlibrary.menu.unmatched': 'Unmatched',
  'sam.archival.menu.archival': 'Archival',
  'sam.archival.menu.myitems': 'My Items',
  'sam.admin.menu.ingestprofiles': 'Ingest Profiles',
  'sam.admin.menu.distributionprofiles': 'Distribution Profiles',
  'sam.admin.menu.assetspecs': 'Asset Specs',
};

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

import FilterContentsBtns from '../buttons/FilterContentsBtns';

type Props = {
  handleClose: () => void;
};

const FilterContents = ({ handleClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Paper>
      <Grid container sx={{ p: '16px' }}>
        {/* Column headers */}
        <Grid item xs={4}>
          <Typography>{t('FIELD')}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>{t('OPERATOR')}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>{t('VALUE')}</Typography>
        </Grid>

        {/* Filter contents - placeholder */}

        {/* Column action buttons */}
        <Grid item xs={12}>
          <FilterContentsBtns handleCancel={handleClose} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default FilterContents;

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CloseBtn from '../common/buttons/CloseBtn';

type Props = {
  onClose: () => void;
};

const AssetsPreview = ({ onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Paper elevation={2} sx={{ borderRadius: 0 }}>
      <Stack spacing={2} justifyContent="space-between" sx={{ minHeight: '100vh' }}>
        <Stack p={3} direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle1">{t('Asset Preview')}</Typography>
          <CloseBtn onClose={onClose} />
        </Stack>
      </Stack>
    </Paper>
  );
};

export default AssetsPreview;

import { backlotDarkThemeExperimental, backlotLightTheme } from '@amzn/sitc-frontend-theme';
import { grey } from '@mui/material/colors';
import CssBaseline from '@mui/material/CssBaseline';
import { alpha, createTheme, ThemeOptions, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import type {} from '@mui/x-data-grid-premium/themeAugmentation';
import React, { useContext } from 'react';

import { ThemeModeContext } from './ThemeMode';

export const white = '#FFFFFF';
export const black = '#000000';
export const greyText = grey[700];
export const muiGrey = grey[300];
export const disabledGrey = grey[200];
export const lightGrey = grey[50];
export const darkGrey = grey[500];

export const studiosBlue = '#007CB6';
export const studiosOrange = '#FF9900';
export const success = '#98C91E';
export const info = '#227D74';
export const warning = '#DCB700';
export const error = '#AD0B31';

export const studiosBlueFade08 = alpha(studiosBlue, 0.08);

export const aqua = '#008296';
export const storm = '#6C7778';
export const forest = '#538000';
export const summer = '#FFC400';
export const gamma = '#8C297D';
export const coral = '#FF7676';

interface ThemeProviderProps {
  children: React.ReactNode;
}

export type ThemeConfig = {
  mode?: 'light' | 'dark';
};

const defaultTheme = createTheme();

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const { themeMode } = useContext(ThemeModeContext);
  const theme = themeMode === 'light' ? backlotLightTheme : backlotDarkThemeExperimental;

  document.body.style.backgroundColor = theme.palette.background.default;

  // Custom theme styling
  const themeOptions: ThemeOptions = {
    ...theme,
    components: {
      ...theme.components,
      MuiAccordion: {
        styleOverrides: {
          root: { '&:before': { display: 'none' } },
          gutters: {
            '&:first-of-type': { borderRadius: 0 },
            '&.Mui-expanded': {
              '&:first-of-type': { marginTop: defaultTheme.spacing(0.5) },
              margin: defaultTheme.spacing(0.5, 0),
            },
            margin: defaultTheme.spacing(0.5, 0),
          },
        },
        defaultProps: { variant: 'elevation', elevation: 0 },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            padding: 0,
            '&.Mui-expanded': { minHeight: 'initial' }, // leaves at 60px, default is 64px
          },
          content: {
            margin: defaultTheme.spacing(2, 0),
            '&.Mui-expanded': { margin: defaultTheme.spacing(2, 0) },
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: 0,
            '> hr': { margin: defaultTheme.spacing(1, 0, 1) },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            '&.MuiChip-colorSuccess': {
              backgroundColor: theme?.palette?.success?.shades?.['12p'],
              '& .MuiChip-icon': {
                color: theme?.palette?.success?.main,
              },
              '& .MuiChip-label': {
                color: theme?.palette?.success?.shades?.['160p'],
              },
            },
            '&.MuiChip-colorWarning': {
              backgroundColor: theme?.palette?.warning.shades?.['12p'],
              '& .MuiChip-icon': {
                color: theme?.palette?.warning?.main,
              },
              '& .MuiChip-label': {
                color: theme?.palette?.warning?.shades?.['160p'],
              },
            },
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundColor: white,
            boxShadow: 'none',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            '&.MuiButton-root': {
              textTransform: 'none',
            },
          },
          outlinedPrimary: {
            '&:hover': {
              backgroundColor: grey[100],
              border: `1px solid ${darkGrey}`,
            },
            backgroundColor: white,
            border: `1px solid ${grey[400]}`,
            lineHeight: '1rem',
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: { border: 0 },
          columnHeader: {
            '&:first-of-type:not(.MuiDataGrid-columnHeaderCheckbox)': { paddingLeft: defaultTheme.spacing(2) },
            '&:last-of-type': { paddingRight: defaultTheme.spacing(2) },
          },
          columnHeaders: {
            borderBottom: `3px solid ${muiGrey}`,
          },
          columnSeparator: { visibility: 'inherit', color: muiGrey },
          cell: {
            padding: `0 ${defaultTheme.spacing(1)}`,
            '&:first-of-type:not(.MuiDataGrid-cellCheckbox)': { paddingLeft: defaultTheme.spacing(2) },
            '&:last-of-type': { paddingRight: defaultTheme.spacing(2) },
          },
        },
      },
      MuiTextField: { defaultProps: { size: 'medium' } },
      MuiList: { styleOverrides: { root: { padding: 0 } } },
      MuiTab: {
        styleOverrides: {
          root: {
            fontSize: '15px',
            fontWeight: 500,
            lineHeight: '26px',
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: '0.625rem',
            fontWeight: 500,
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            cursor: 'pointer',
          },
        },
        defaultProps: { underline: 'hover' },
      },
    },
  };

  return (
    <MuiThemeProvider theme={themeOptions}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { CSSObject, styled, Theme } from '@mui/material/styles';
import * as React from 'react';
import { useState } from 'react';

import { drawerTransition, MiniPanelDrawerWidth, PanelDrawerWidth } from '../../constants/drawer';
import { AssetDetailPanelItem } from '../../enums/AssetDetailPanelItem';

const drawerMixin = (open: boolean, theme: Theme): CSSObject => ({
  border: 'none',
  overflowX: 'hidden',
  marginRight: 30,
  marginTop: 220,
  height: '75vh',
  transition: drawerTransition(open, theme),
  width: open ? PanelDrawerWidth : MiniPanelDrawerWidth,
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: PanelDrawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...drawerMixin(Boolean(open), theme),
  '& .MuiDrawer-paper': { ...drawerMixin(Boolean(open), theme), position: 'absolute' },
}));

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const AssetDetailPanel = ({ open, setOpen }: Props) => {
  const [selected, setSelected] = useState<AssetDetailPanelItem>();

  // This needs to check
  const menu = [
    {
      name: AssetDetailPanelItem.Info,
      icon: <InfoOutlinedIcon />,
    },
    {
      name: AssetDetailPanelItem.Feed,
      icon: <HistoryOutlinedIcon />,
    },
    {
      name: AssetDetailPanelItem.Folder,
      icon: <FolderOpenOutlinedIcon />,
    },
    {
      name: AssetDetailPanelItem.Lock,
      icon: <LockOutlinedIcon />,
    },
  ];

  return (
    <Drawer variant="permanent" open={open} anchor="right">
      <Paper elevation={2} sx={{ height: '100%' }}>
        <Stack height="100%" direction="row" justifyContent="flex-end" width="100%">
          {open && <Stack>{/* Place main content here */}</Stack>}

          <Stack direction="row" justifyContent="flex-end">
            {open && <Divider orientation="vertical" />}

            <Stack justifyContent="space-between" height="100%" alignItems="center" width={MiniPanelDrawerWidth}>
              <List sx={{ width: '100%', marginTop: 1 }}>
                {menu.map((item) => (
                  <ListItem
                    key={item.name}
                    disablePadding
                    sx={{
                      '& .MuiListItemIcon-root': { minWidth: 4 },
                      borderLeft: (theme) => (selected === item.name && open ? `1px solid ${theme.palette.primary.main}` : 'none'),
                      marginLeft: selected === item.name && open ? '-1px' : 'inherit', // to prevent element moving due to border when selected
                    }}>
                    <ListItemButton
                      sx={{ justifyContent: 'center', paddingTop: 2, paddingBottom: 2 }}
                      onClick={() => setSelected(item.name)}>
                      <ListItemIcon
                        sx={{ color: (theme) => (selected === item.name ? theme.palette.primary.main : theme.palette.action.active) }}>
                        {item.icon}
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>

              <Stack direction="row" justifyContent="flex-end" py={1}>
                <IconButton onClick={() => setOpen(!open)} sx={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)' }}>
                  <ChevronLeftIcon />
                </IconButton>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Paper>
    </Drawer>
  );
};

export default AssetDetailPanel;

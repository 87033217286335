import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import React from 'react';
import { useParams } from 'react-router-dom';

// Placeholder
const TitleDetailMain = () => {
  const { id } = useParams();

  console.log('titleId: ', id); // for testing purpose

  return (
    <Stack>
      <Box>Title detail</Box>
    </Stack>
  );
};

export default TitleDetailMain;

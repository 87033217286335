import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import React from 'react';

import MainHeader from '../common/MainHeader';
import TitleTree from './TitleTree';

type Props = {
  dateTestId: string;
  children: React.ReactNode;
  title?: string | null;
  subTitle?: string | null;
  hasTree?: boolean;
};

const TitleDetailViewWrapper = ({ dateTestId, children, title, subTitle, hasTree = true }: Props) => (
  <Box data-testid={dateTestId} display="flex" p={3}>
    {hasTree && <TitleTree />}

    <Stack width="100%" spacing={2}>
      <Stack width="100%" spacing={2}>
        <Stack direction="row" width="100%" alignItems="center" justifyContent="space-between">
          <MainHeader title={title} subTitle={subTitle} isFavorite />
        </Stack>
      </Stack>

      <Box component="main" sx={{ flexGrow: 1 }}>
        {children}
      </Box>
    </Stack>
  </Box>
);

export default TitleDetailViewWrapper;

import { AssetStatus } from '@amzn/ce-browse-models';
import CheckIcon from '@mui/icons-material/Check';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import PrivacyTipOutlinedIcon from '@mui/icons-material/PrivacyTipOutlined';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import React from 'react';

type Props = {
  status?: string | null;
};

const AssetOrderStatus = ({ status }: Props) => {
  if (!status) {
    return null;
  }

  const chipStyle = {
    [AssetStatus.Approved]: {
      icon: <CheckIcon />,
      color: 'success',
    },
    [AssetStatus.Quarantined]: {
      icon: <PrivacyTipOutlinedIcon />,
      color: 'warning',
    },
    [AssetStatus.QcApproved]: {
      icon: <CheckIcon />,
      color: 'success',
    },
    [AssetStatus.Available]: {
      icon: <InventoryOutlinedIcon />,
      color: 'success',
    },
  };

  return (
    <Box data-testid={status} alignItems="center" display="flex">
      <Chip icon={chipStyle[status as AssetStatus].icon} color={chipStyle[status].color} label={status} variant="filled" size="small" />
    </Box>
  );
};

export default AssetOrderStatus;

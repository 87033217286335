import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tab } from '../../enums/Tab';
import ViewWrapper from '../common/ViewWrapper';
import ArchivalMain from './ArchivalMain';

const tempPaths = ['S3', 'aom-archive-protek', 'From_Deluxe'];

const Archival = () => {
  const { t } = useTranslation();

  return (
    <ViewWrapper
      title={t('Archival')}
      dateTestId="archival-view"
      tab={Tab.Archival}
      hasExportCsv
      paths={tempPaths}
      hasFilter
      hasSearchBar
      hasTotalCount>
      <ArchivalMain />
    </ViewWrapper>
  );
};

export default Archival;

import { Region } from '../enums/Region';
import { Stage } from '../enums/Stage';

export const appSyncConfig = {
  [Stage.Personal]: {
    region: Region.UsEast1,
    endpoint: 'https://4d46gzrwnfgchjiovqpk7qppgy.appsync-api.us-east-1.amazonaws.com/graphql',
  },
  [Stage.Dev]: {
    region: Region.UsEast1,
    endpoint: 'https://4d46gzrwnfgchjiovqpk7qppgy.appsync-api.us-east-1.amazonaws.com/graphql',
  },
  [Stage.Beta]: {
    region: Region.UsEast1,
    endpoint: 'https://norsocymevhy3arr76qgkhpgce.appsync-api.us-east-1.amazonaws.com/graphql',
  },
  [Stage.Gamma]: {
    region: Region.UsEast1,
    endpoint: 'https://ks5amclkzrhqxhmirvvir2zd6i.appsync-api.us-east-1.amazonaws.com/graphql',
  },
  [Stage.Prod]: {
    region: Region.UsEast1,
    endpoint: 'https://5vxzxwzt45dm3o2uoju5w6rkum.appsync-api.us-east-1.amazonaws.com/graphql',
  },
};

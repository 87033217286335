import Button from '@mui/material/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  onClick?: () => void;
};

const IngestAssetsBtn = ({ onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <Button variant="contained" onClick={onClick}>
      {t('Ingest Assets')}
    </Button>
  );
};

export default IngestAssetsBtn;

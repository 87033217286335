export enum ViewAssetType {
  Audio = 'audio',
  Video = 'video',
  TimedText = 'timedText',
  Document = 'document',
  Image = 'image',
  Placeholder = 'placeholder',
  ImageSequence = 'imageSequence',
  Unknown = 'unknown',
}

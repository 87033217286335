export enum AssetsTableColumn {
  FileName = 'fileName',
  AssetSpecName = 'assetSpecName',
  Version = 'version',
  AssetStatus = 'assetStatus',
  WorkOrderName = 'workOrderName',
  AssignedVendor = 'assignedVendor',
  OrderStatus = 'orderStatus',
  DateModified = 'dateModified',
  ModifiedBy = 'modifiedBy',
  DueDate = 'dueDate',
  TitleName = 'titleName',
  IngestProfileName = 'ingestProfileName',
  LanguageLocale = 'languageLocale',
  FileSize = 'fileSize',
  FileExtension = 'fileExtension',
  DistributionProfileName = 'distributionProfileName',
  ProjectName = 'projectName',
  WorkOrderType = 'workOrderType',
  DateCreated = 'dateCreated',
  CreatedBy = 'createdBy',
  ShowCode = 'showCode',
  TitleSlugId = 'titleSlugId',
  TitleType = 'titleType',
  SeasonNumber = 'seasonNumber',
  EpisodeProductionNumber = 'episodeProductionNumber',
  AmazonStudiosAssetId = 'amazonStudiosAssetId',
  AssetSpecType = 'assetSpecType',
  AssetSpecSubType = 'assetSpecSubType',
  DirectPath = 'directPath',
}

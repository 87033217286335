import { UniversalHeader, UniversalHeaderProps } from '@amzn/sitc-frontend/components';
import { AuthSession } from '@amzn/sitc-frontend/contexts';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import HeaderProfileMenu from '../../constants/header-configuration/HeaderProfileMenu';
import HeaderSupportLink from '../../constants/header-configuration/HeaderSupportLink';
import { ThemeModeContext } from '../../context/ThemeMode';
import { ThemeType } from '../../enums/ThemeType';

type Props = {
  basePath: string;
  authSession: AuthSession;
};

const Header = ({ basePath, authSession }: Props) => {
  const { t } = useTranslation();

  const prefix = basePath === '/' ? basePath : `${basePath}/`;
  const { setThemeMode, themeMode } = useContext(ThemeModeContext);

  const headerOptions: UniversalHeaderProps = {
    productIdentifier: t('sam.header.source'),
    productIdentifierPath: `${prefix}asset-library`,
    utilities: [HeaderSupportLink(), HeaderProfileMenu(authSession.userId)],
    headerLinks: [
      {
        primary: 'Asset Library',
        path: `${prefix}asset-library`,
      },
      {
        primary: 'Archival',
        path: `${prefix}archival`,
      },
      {
        primary: 'Admin',
        path: `${prefix}admin`,
      },
    ],
    appSwitcherContent: {
      // Placehoder for app links in Studios Tech
      navItems: [
        {
          iconKey: 'DashboardOutlined',
          primary: 'universal_header.appswitcher.studiosapps.primary',
          secondary: 'universal_header.appswitcher.studiosapps.secondary',
          path: 'https://studiosapps.com', // Currently, URL won't work
          newTab: true,
        },
      ],
    },
    logo: (
      <img
        alt="logo"
        src="https://m.media-amazon.com/images/G/01/backlot/assets/img/source-light.svg"
        style={{ margin: '0 2px 0 8px', filter: `invert(${themeMode === ThemeType.Dark ? '100%' : '0%'})` }}
      />
    ),
    enableThemeSwitcher: true,
    onThemeChange: setThemeMode,
  };

  return <UniversalHeader {...headerOptions} />;
};

export default Header;

import ClosedCaptionIcon from '@mui/icons-material/ClosedCaption';
import DescriptionIcon from '@mui/icons-material/Description';
import HelpIcon from '@mui/icons-material/Help';
import ImageIcon from '@mui/icons-material/Image';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import MovieIcon from '@mui/icons-material/Movie';
import SpeakerIcon from '@mui/icons-material/Speaker';
import Avatar from '@mui/material/Avatar';
import { Theme } from '@mui/material/styles';
import React from 'react';

import { aqua, coral, forest, gamma, storm, summer } from '../../../context/Theme';
import { ViewAssetType } from '../../../enums/ViewAssetType';

const useStyles = {
  [ViewAssetType.Audio]: {
    color: (theme: Theme) => theme.palette.common.white,
    backgroundColor: gamma,
  },
  [ViewAssetType.Video]: {
    color: (theme: Theme) => theme.palette.common.white,
    backgroundColor: aqua,
  },
  [ViewAssetType.ImageSequence]: {
    color: (theme: Theme) => theme.palette.common.white,
    backgroundColor: aqua,
  },
  [ViewAssetType.TimedText]: {
    color: (theme: Theme) => theme.palette.common.black,
    backgroundColor: summer,
  },
  [ViewAssetType.Document]: {
    color: (theme: Theme) => theme.palette.common.white,
    backgroundColor: forest,
  },
  [ViewAssetType.Image]: {
    color: (theme: Theme) => theme.palette.common.black,
    backgroundColor: coral,
  },
  [ViewAssetType.Placeholder]: {
    color: (theme: Theme) => theme.palette.common.white,
    backgroundColor: storm,
  },
  [ViewAssetType.Unknown]: {
    color: (theme: Theme) => theme.palette.common.white,
    backgroundColor: (theme: Theme) => theme.palette.grey['500'],
  },
};

const useStylesInvert = {
  [ViewAssetType.Audio]: { color: gamma },
  [ViewAssetType.Video]: { color: aqua },
  [ViewAssetType.ImageSequence]: { color: aqua },
  [ViewAssetType.TimedText]: { color: summer },
  [ViewAssetType.Document]: { color: forest },
  [ViewAssetType.Image]: { color: coral },
  [ViewAssetType.Placeholder]: { color: storm },
  [ViewAssetType.Unknown]: { color: (theme: Theme) => theme.palette.grey['600'] },
};

type AssetTypeIconProps = {
  assetType: ViewAssetType | string;
  isInverted?: boolean;
};

const iconMap: Record<ViewAssetType, React.ReactNode> = {
  [ViewAssetType.Audio]: <SpeakerIcon />,
  [ViewAssetType.Video]: <MovieIcon />,
  [ViewAssetType.ImageSequence]: <MovieIcon />,
  [ViewAssetType.TimedText]: <ClosedCaptionIcon />,
  [ViewAssetType.Document]: <DescriptionIcon />,
  [ViewAssetType.Image]: <ImageIcon />,
  [ViewAssetType.Placeholder]: <InsertDriveFileIcon />,
  [ViewAssetType.Unknown]: <HelpIcon />,
};

const AssetTypeIcon = ({ assetType, isInverted = false }: AssetTypeIconProps) => {
  const baseStyles = { backgroundColor: 'transparent' };
  const overrideStyles = isInverted ? useStylesInvert[assetType] : useStyles[assetType];

  return (
    <Avatar sx={{ ...baseStyles, ...overrideStyles }} variant="rounded">
      {iconMap[assetType]}
    </Avatar>
  );
};

export default AssetTypeIcon;

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import React from 'react';

import { Tab } from '../../enums/Tab';
import { ViewAssetType } from '../../enums/ViewAssetType';
import ExportCsvBtn from './buttons/ExportCsvBtn';
import IngestAssetsBtn from './buttons/IngestAssetsBtn';
import Filter from './filter/Filter';
import FolderBreadcrumb from './FolderBreadcrumb';
import MainHeader from './MainHeader';
import SearchBar from './SearchBar';
import SideMenu from './SideMenu';
import TotalCount from './TotalCount';

type Props = {
  tab?: Tab;
  title?: string | null;
  dateTestId: string;
  children: React.ReactNode;
  paths?: Array<string>;
  hasExportCsv?: boolean;
  hasSearchBar?: boolean;
  hasFilter?: boolean;
  hasTotalCount?: boolean;
  hasIngestBtn?: boolean;
  subTitle?: string;
  detailTitle?: string;
  detailSubTitle?: string;
  assetType?: ViewAssetType;
  isFavorite?: boolean;
  totalCount?: number;
};

const ViewWrapper = ({
  tab,
  title,
  dateTestId,
  children,
  paths,
  hasExportCsv,
  hasSearchBar,
  hasFilter,
  hasTotalCount,
  hasIngestBtn,
  subTitle,
  detailTitle,
  detailSubTitle,
  assetType,
  isFavorite,
  totalCount,
}: Props) => (
  <Box data-testid={dateTestId} display="flex" p={3}>
    {tab && <SideMenu tab={tab} />}

    <Stack width="100%" spacing={2}>
      <Stack width="100%" spacing={2}>
        <Stack width="100%" spacing={2} direction="row" justifyContent="space-between" alignItems="center">
          <Box>
            {paths && <FolderBreadcrumb paths={paths} />}

            <MainHeader
              title={title}
              subTitle={subTitle}
              detailTitle={detailTitle}
              detailSubTitle={detailSubTitle}
              assetType={assetType}
              isFavorite={isFavorite}
            />
          </Box>

          {hasExportCsv && <ExportCsvBtn />}
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
          <Stack direction="row" spacing={2} alignItems="center">
            {hasFilter && <Filter />}

            {hasSearchBar && <SearchBar title={title} />}

            {hasTotalCount && <TotalCount totalCount={totalCount} />}
          </Stack>

          <Box>{hasIngestBtn && <IngestAssetsBtn />}</Box>
        </Stack>
      </Stack>

      <Box component="main" sx={{ flexGrow: 1 }}>
        {children}
      </Box>
    </Stack>
  </Box>
);

export default ViewWrapper;

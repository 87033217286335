import SettingsBackupRestoreOutlined from '@mui/icons-material/SettingsBackupRestoreOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  handleApply?: () => void;
  handleClear?: () => void;
  handleCancel?: () => void;
};

const FilterContentsBtns = ({ handleApply, handleClear, handleCancel }: Props) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" justifyContent="space-between">
      <Box>
        <Button variant="text" onClick={handleClear} startIcon={<SettingsBackupRestoreOutlined />}>
          {t('Clear All')}
        </Button>
      </Box>

      <Stack direction="row" spacing={2}>
        <Button variant="text" onClick={handleCancel}>
          {t('Cancel')}
        </Button>
        <Button variant="contained" onClick={handleApply}>
          {t('Apply Filters')}
        </Button>
      </Stack>
    </Box>
  );
};

export default FilterContentsBtns;

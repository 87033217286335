import { SAMAsset } from '@amzn/ce-browse-models';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { drawerTransition, MiniPanelDrawerWidth, PanelDrawerWidth } from '../../constants/drawer';
import Player from '../common/player/Player';
import AssetDetailMetadata from './AssetDetailMetadata';
import AssetDetailPanel from './AssetDetailPanel';

type Props = {
  data: SAMAsset;
};

// Placeholder
const AssetDetailMain = ({ data }: Props) => {
  const [open, setOpen] = useState<boolean>(true);
  const { id } = useParams();

  console.log('assetId: ', id); // for testing purpose

  return (
    <Stack>
      <Box
        sx={{
          // To shrink the main view according to the panel open/close
          width: `calc(100% - ${open ? PanelDrawerWidth : MiniPanelDrawerWidth} - 1rem)`,
          transition: (theme) => drawerTransition(open, theme),
        }}>
        <AssetDetailMetadata data={data} />
        <Player />
      </Box>

      <AssetDetailPanel open={open} setOpen={setOpen} />
    </Stack>
  );
};

export default AssetDetailMain;

import { gql } from '@apollo/client';

const getProfiles = gql`
  query GetProfiles($input: GetProfilesInput!) {
    getProfiles(input: $input) {
      member {
        studiosProfileId
        profileNumber
        profileName
        profileClass
        profileType
        status
        createdBy
        lastEditedBy
        createdDate
        onboardDate
        workflowTemplate
        workflowTargets {
          deliveryMethod
          targetAddress
        }
        profileTargets {
          deliveryMethod
          targetAddress
        }
        businessUnit
        associatedParties
        profileUsers
        metadata
        profileData {
          ingestData {
            uploadType
            storageLocation
          }
          deliveryData {
            deliveryType
            deliveryDestination
          }
        }
        extraWorkflowParams
      }
      lastEvaluatedKey
    }
  }
`;

export default getProfiles;

import { mockSamAsset } from '@amzn/ce-browse-models';
import React from 'react';
import { useParams } from 'react-router-dom';

import pathManifest from '../../constants/pathManifest';
import { ViewAssetType } from '../../enums/ViewAssetType';
import AssetDetailMain from './AssetDetailMain';
import AssetDetailViewWrapper from './AssetDetailViewWrapper';

const tempPaths = ['S3', 'aom-archive-protek', 'From_Deluxe'];

// Placeholder
const AssetDetail = () => {
  const { id } = useParams();

  console.log('assetId: ', id); // for testing purpose

  return (
    <AssetDetailViewWrapper
      dateTestId="asset-detail-view"
      paths={tempPaths}
      detailTitle={mockSamAsset.title.titleName}
      fileName={mockSamAsset.fileName}
      assetType={ViewAssetType.Video}
      previous={pathManifest.assetLibrary.assets.index}
      id="123456789">
      <AssetDetailMain data={mockSamAsset} />
    </AssetDetailViewWrapper>
  );
};

export default AssetDetail;

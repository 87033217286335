import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import Button from '@mui/material/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  onClick?: () => void;
};

const ExportCsvBtn = ({ onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <Button variant="text" onClick={onClick} startIcon={<DownloadOutlinedIcon />} color="secondary">
      {t('Export CSV')}
    </Button>
  );
};

export default ExportCsvBtn;

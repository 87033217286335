// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Box from '@mui/material/Box';
import React from 'react';

// Placeholder for accurate player
const Player = () => (
  <Box display="flex" flexDirection="column" height="100%" justifyContent="center" width="100%">
    <Box component="av-application">
      <Box alignSelf="center" display="flex" justifyContent="center" minWidth="100%" position="relative">
        <Box component="video" height="auto" minWidth="100%" />
        <Box component="apc-controls" height="auto" minWidth="100%" />
      </Box>
    </Box>
  </Box>
);

export default Player;

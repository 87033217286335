import { Theme } from '@mui/material/styles';

export const DrawerWidth = 240;
export const TreeDrawerWidth = '20rem';
export const PanelDrawerWidth = '28rem';
export const MiniPanelDrawerWidth = '4rem';

export const drawerTransition = (open: boolean, theme: Theme) =>
  theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: open ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
  });

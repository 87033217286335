import StarIcon from '@mui/icons-material/Star';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ViewAssetType } from '../../enums/ViewAssetType';
import AssetTypeIcon from './icons/AssetTypeIcon';

type Props = {
  title?: string | null;
  subTitle?: string | null;
  assetType?: ViewAssetType;
  detailTitle?: string | null;
  detailSubTitle?: string;
  fileName?: string | null;
  id?: string;
  isFavorite?: boolean;
};

const MainHeader = ({ title, subTitle, detailTitle, detailSubTitle, fileName, assetType, isFavorite, id }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      {title && (
        <Stack direction="row" spacing={2} alignItems="center">
          {isFavorite && (
            <Stack>
              <StarIcon color="primary" />
            </Stack>
          )}
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="h4">{title}</Typography>
            {subTitle && <Typography color="text.secondary">{subTitle}</Typography>}
          </Stack>
        </Stack>
      )}
      {detailTitle && (
        <Stack direction="row" spacing={2}>
          {assetType && (
            <Stack>
              <AssetTypeIcon assetType={assetType} />
            </Stack>
          )}
          <Stack justifyContent="center">
            <Typography variant="h5">{detailTitle}</Typography>
            <Stack direction="row">
              {detailSubTitle && (
                <Typography variant="caption" color="text.secondary" mr={1}>
                  {detailSubTitle}
                </Typography>
              )}
              {fileName && (
                <Typography variant="caption" color="text.secondary" mr={1}>
                  {`${t('File Name')} ${fileName}`}
                </Typography>
              )}
              {id && (
                <Typography variant="caption" color="text.secondary">
                  {`${t('ID')} ${id}`}
                </Typography>
              )}
            </Stack>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default MainHeader;

import FilterListOutlined from '@mui/icons-material/FilterListOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Popper from '@mui/material/Popper';
import React, { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FilterContents from './FilterContents';

const Filter = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box display="flex">
      <Button variant="text" onClick={handleClick} startIcon={<FilterListOutlined />}>
        {t('Filter')}
      </Button>
      <Popper
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 10],
            },
          },
        ]}
        id="filter-popper"
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start">
        <FilterContents handleClose={handleClose} />
      </Popper>
    </Box>
  );
};

export default Filter;

import { TitleMetadataV2 } from '@amzn/ce-browse-models';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import pathManifest from '../../constants/pathManifest';
import { ItemType } from '../../types';
import PreviewDrawer from '../common/preview/PreviewDrawer';
import StarCheckBox from '../common/table/StarCheckBox';
import Table from '../common/table/Table';
import { columns } from './table/TitlesTableColumns';
import TitlesPreview from './TitlesPreview';

type Props = {
  data: Array<TitleMetadataV2>;
};

// Placeholder
const TitlesMain = ({ data }: Props) => {
  const [selectedItem, setSelectedItem] = useState<ItemType>();
  const navigate = useNavigate();

  const handleClosePreview = () => {
    setSelectedItem(undefined);
  };

  return (
    <>
      <Table
        data={data}
        columns={columns}
        setSelectedItem={setSelectedItem}
        slots={{ baseCheckbox: StarCheckBox }}
        onRowDoubleClick={({ row }) => navigate(`${pathManifest.assetLibrary.titles.index}/${row?.titleId as string}`)}
      />

      <PreviewDrawer open={!!selectedItem} onClose={handleClosePreview}>
        <TitlesPreview onClose={handleClosePreview} titleId={(selectedItem as TitleMetadataV2)?.titleId} />
      </PreviewDrawer>
    </>
  );
};

export default TitlesMain;

import { PageLoading } from '@amzn/sitc-frontend/components';
import { AuthSessionProvider, MicroAppConfigProvider } from '@amzn/sitc-frontend/contexts';
import { MicroAppInitializationProps } from '@amzn/sitc-frontend/types';
import { MUI_LICENSE_KEY } from '@amzn/studios-material-ui-x/src/globals';
import Box from '@mui/material/Box';
import { LicenseInfo } from '@mui/x-license-pro';
import React, { Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { ApolloProvider } from '../context/Apollo';
import { ThemeProvider } from '../context/Theme';
import { ThemeModeProvider } from '../context/ThemeMode';
import { Stage } from '../enums/Stage';
import i18n from '../i18n';
import { appDomainTemplate, MicroAppConfigContext, MicroAppConfigResultType } from '../utils/app';
import Header from './common/Header';
import { FeatureConfiguration } from './FeatureConfiguration';
import Routes from './Routes';

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

const StudiosAssetManagement: React.FC<MicroAppInitializationProps> = (props) => {
  const {
    authSession,
    basePath,
    stage,
    appId,
    featureOverrides,
    microAppName,
    userPreferences: {
      preferences: { theme },
    },
  } = props;

  return (
    <MicroAppConfigProvider<MicroAppConfigResultType>
      appDomainTemplate={appDomainTemplate}
      appId={appId}
      appLoader={<PageLoading />}
      appName={microAppName}
      context={MicroAppConfigContext}
      stage={stage}>
      <ThemeModeProvider themePreference={theme}>
        <ThemeProvider>
          <FeatureConfiguration featureOverrides={featureOverrides}>
            <I18nextProvider i18n={i18n}>
              <BrowserRouter basename={basePath}>
                <AuthSessionProvider authSession={authSession}>
                  <ApolloProvider stage={stage as Stage} authSession={authSession}>
                    <Box sx={{ minHeight: '100vh' }}>
                      <Header basePath={basePath} authSession={authSession} />
                      <Suspense fallback="loading...">
                        <QueryParamProvider adapter={ReactRouter6Adapter}>
                          <Routes />
                        </QueryParamProvider>
                      </Suspense>
                    </Box>
                  </ApolloProvider>
                </AuthSessionProvider>
              </BrowserRouter>
            </I18nextProvider>
          </FeatureConfiguration>
        </ThemeProvider>
      </ThemeModeProvider>
    </MicroAppConfigProvider>
  );
};

export default StudiosAssetManagement;

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import React from 'react';
import { Link } from 'react-router-dom';

import { ViewAssetType } from '../../enums/ViewAssetType';
import DetailViewBtns from '../common/buttons/DetailViewBtns';
import FolderBreadcrumb from '../common/FolderBreadcrumb';
import MainHeader from '../common/MainHeader';

type Props = {
  dateTestId: string;
  children: React.ReactNode;
  paths?: Array<string>;
  detailTitle?: string | null;
  detailSubTitle?: string;
  assetType?: ViewAssetType;
  fileName?: string | null;
  id?: string;
  previous?: string;
};

const AssetDetailViewWrapper = ({ previous, fileName, id, dateTestId, children, paths, detailTitle, detailSubTitle, assetType }: Props) => (
  <Box data-testid={dateTestId} display="flex" p={3}>
    <Stack width="100%" spacing={2}>
      <Stack width="100%" spacing={2}>
        <Stack width="100%" spacing={2} direction="row" alignItems="center">
          {previous && (
            <IconButton component={Link} to={previous}>
              <ArrowBackIcon />
            </IconButton>
          )}

          <Divider orientation="vertical" />

          {paths && <FolderBreadcrumb paths={paths} />}
        </Stack>
        <Stack direction="row" width="100%" alignItems="center" justifyContent="space-between">
          <MainHeader detailTitle={detailTitle} detailSubTitle={detailSubTitle} assetType={assetType} fileName={fileName} id={id} />

          <DetailViewBtns />
        </Stack>
      </Stack>

      <Box component="main" sx={{ flexGrow: 1 }}>
        {children}
      </Box>
    </Stack>
  </Box>
);

export default AssetDetailViewWrapper;

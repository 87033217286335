import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tab } from '../../enums/Tab';
import ViewWrapper from '../common/ViewWrapper';
import IngestProfilesMain from './IngestProfilesMain';

const IngestProfiles = () => {
  const { t } = useTranslation();

  return (
    <ViewWrapper title={t('Ingest Profiles')} dateTestId="ingest-profiles-view" tab={Tab.Admin} hasExportCsv>
      <IngestProfilesMain />
    </ViewWrapper>
  );
};

export default IngestProfiles;

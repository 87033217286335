import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Checkbox from '@mui/material/Checkbox';
import React, { forwardRef } from 'react';

const StarCheckbox = forwardRef<HTMLButtonElement>((props, ref) => (
  <Checkbox icon={<StarBorderIcon />} checkedIcon={<StarIcon />} ref={ref} {...props} />
));

StarCheckbox.displayName = 'StarCheckbox';

export default StarCheckbox;

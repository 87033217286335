import CloseIcon from '@mui/icons-material/Close';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Stack from '@mui/system/Stack';
import React from 'react';
import { useTranslation } from 'react-i18next';

const style = {
  paper: (theme: Theme) => ({
    backgroundColor: theme.palette.primary.shades?.['16p'],
    padding: theme.spacing(0.5, 2.0),
    margin: theme.spacing(0, 0, 1, 0),
  }),
};

type Props = {
  selectedCount: number;
  onClearSelection: () => void;
  isEditable: boolean;
  handleDownload?: () => void;
  handleEdit?: () => void;
  handleSend?: () => void;
};

const TableActionBar = ({ selectedCount, onClearSelection, isEditable, handleDownload, handleSend, handleEdit }: Props) => {
  const { t } = useTranslation();

  return (
    <Paper sx={style.paper}>
      <Stack direction="row" width="100%" alignItems="center" justifyContent="space-between" spacing={2}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton onClick={onClearSelection}>
            <CloseIcon />
          </IconButton>
          <Typography variant="body1">
            {selectedCount} {t('selected')}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <IconButton onClick={handleDownload}>
            <DownloadOutlinedIcon />
          </IconButton>
          {isEditable && (
            <IconButton onClick={handleEdit}>
              <EditIcon />
            </IconButton>
          )}
          <IconButton onClick={handleSend}>
            <SendIcon />
          </IconButton>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default TableActionBar;

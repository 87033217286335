import { mockSamTitles } from '@amzn/ce-browse-models';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tab } from '../../enums/Tab';
import ViewWrapper from '../common/ViewWrapper';
import TitlesMain from './TitlesMain';

const Titles = () => {
  const { t } = useTranslation();

  const data = mockSamTitles;

  return (
    <ViewWrapper
      title={t('Titles')}
      dateTestId="titles-view"
      tab={Tab.AssetLibrary}
      hasExportCsv
      hasSearchBar
      hasFilter
      hasTotalCount
      totalCount={data.length}>
      <TitlesMain data={data} />
    </ViewWrapper>
  );
};

export default Titles;

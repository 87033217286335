import { SAMAsset, TitleMetadataV2 } from '@amzn/ce-browse-models';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Paper from '@mui/material/Paper';
import { UncapitalizeObjectKeys } from '@mui/x-data-grid/internals';
import {
  DataGridPremium,
  GridColDef,
  GridEventListener,
  GridPaginationModel,
  GridRowSelectionModel,
  GridSlotsComponent,
  GridValueGetterParams,
} from '@mui/x-data-grid-premium';
import React, { useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Page, PageSize, PageSizeOptions, TableWidth } from '../../../constants/table';
import { ModelFields } from '../../../enums/ModelFields';
import { ItemType } from '../../../types';

type Props = {
  data: Array<TitleMetadataV2 | SAMAsset>;
  columns: (intl: TFunction<'translation', undefined>, rowSelectionModel?: GridRowSelectionModel) => Array<GridColDef>;
  setSelectedItem?: (selectedItem: ItemType) => void;
  slots?: UncapitalizeObjectKeys<Partial<GridSlotsComponent>>;
  onRowDoubleClick?: GridEventListener<'rowDoubleClick'>;
  onCellClick?: GridEventListener<'cellClick'>;
  width?: string;
  onRowSelectionModelChange?: (selection: GridRowSelectionModel) => void;
  currentSelection?: GridRowSelectionModel;
};

const Table = ({
  width = TableWidth,
  data,
  columns,
  setSelectedItem,
  slots,
  onRowDoubleClick,
  onCellClick,
  onRowSelectionModelChange,
  currentSelection = [],
}: Props) => {
  const { t: intl } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const getParamAsNumber = (paramName: string, defaultValue: number): number => {
    const param = searchParams.get(paramName);
    if (param) {
      return parseInt(param, 10);
    }
    return defaultValue;
  };

  const [paginationModel, setPaginationModel] = useState({
    pageSize: getParamAsNumber(PageSize, 25),
    page: getParamAsNumber(Page, 1) - 1,
  });

  const handleGetRowId = (rowItem: TitleMetadataV2 | SAMAsset): string => {
    if (ModelFields.AmazonStudiosAssetId in rowItem) {
      return rowItem.amazonStudiosAssetId;
    }
    if (ModelFields.TitleId in rowItem) {
      return rowItem.titleId as string;
    }
    return '';
  };

  const onPaginationModelChange = (model: GridPaginationModel) => {
    searchParams.set(Page, (model.page + 1).toString());
    searchParams.set(PageSize, model.pageSize.toString());
    setSearchParams(searchParams);
    setPaginationModel(model);
  };

  const start = paginationModel.page * paginationModel.pageSize;
  const rowPageData = data.slice(start, start + paginationModel.pageSize);

  return (
    <Paper sx={{ width }}>
      <DataGridPremium
        autoHeight
        pageSizeOptions={PageSizeOptions}
        initialState={{
          pagination: {
            paginationModel,
          },
        }}
        onPaginationModelChange={onPaginationModelChange}
        paginationMode="server"
        rowCount={data.length}
        pagination
        columns={columns(intl, currentSelection)}
        checkboxSelection
        disableColumnMenu
        rowSelectionModel={currentSelection}
        disableRowSelectionOnClick
        onCellClick={onCellClick}
        onRowClick={({ row }: Pick<GridValueGetterParams, 'row'>) => {
          if (setSelectedItem) {
            setSelectedItem(row as ItemType);
          }
        }}
        onRowDoubleClick={onRowDoubleClick}
        onRowSelectionModelChange={onRowSelectionModelChange}
        disableColumnSelector
        getRowId={handleGetRowId}
        rows={rowPageData}
        slots={{
          columnSortedAscendingIcon: KeyboardArrowUpIcon,
          columnSortedDescendingIcon: KeyboardArrowDownIcon,
          ...slots,
        }}
      />
    </Paper>
  );
};

export default Table;

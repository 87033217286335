import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import React from 'react';

import { AppBarHeight } from '../../constants/app';
import { DrawerWidth } from '../../constants/drawer';
import { Tab } from '../../enums/Tab';
import ListMenu from './tabs/ListMenu';

type Props = {
  tab: Tab;
};

const SideMenu = ({ tab }: Props) => (
  <Drawer anchor="left" sx={{ width: DrawerWidth }} variant="permanent">
    <Box sx={{ width: DrawerWidth, marginTop: `${AppBarHeight}px` }}>
      <ListMenu tab={tab} />
    </Box>
  </Drawer>
);

export default SideMenu;

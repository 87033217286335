import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tab } from '../../enums/Tab';
import ViewWrapper from '../common/ViewWrapper';
import MyItemsMain from './MyItemsMain';

const MyItems = () => {
  const { t } = useTranslation();

  return (
    <ViewWrapper title={t('My Items')} dateTestId="my-items-view" tab={Tab.Archival} hasExportCsv hasSearchBar hasFilter hasTotalCount>
      <MyItemsMain />
    </ViewWrapper>
  );
};

export default MyItems;

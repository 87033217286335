import ClickAwayListener from '@mui/material/ClickAwayListener';
import Drawer from '@mui/material/Drawer';
import React, { ReactNode } from 'react';

type Props = {
  children?: ReactNode;
  open: boolean;
  onClose?: () => void;
  width?: string;
  variant?: 'permanent' | 'persistent' | 'temporary';
};

// Placeholder
const PreviewDrawer = ({ children, open, onClose, variant = 'persistent', width = '25rem' }: Props) => (
  <ClickAwayListener mouseEvent="onMouseDown" onClickAway={() => onClose && onClose()}>
    <Drawer
      ModalProps={{ keepMounted: true }}
      PaperProps={{
        sx: {
          border: 'none',
          width,
          zIndex: 1300,
        },
      }}
      anchor="right"
      open={open}
      variant={variant}>
      {children}
    </Drawer>
  </ClickAwayListener>
);

export default PreviewDrawer;

import React from 'react';

import useGetProfilesQuery from '../../hooks/useGetProfilesQuery';

// Placeholder
const DistributionProfilesMain = () => {
  const { data } = useGetProfilesQuery({ businessUnit: 'Post Production' });

  console.log(data);

  return <>Distribution Profiles Main</>;
};

export default DistributionProfilesMain;

import { mockSamAsset } from '@amzn/ce-browse-models';
import React from 'react';
import { useParams } from 'react-router-dom';

import TitleDetailMain from './TitleDetailMain';
import TitleDetailViewWrapper from './TitleDetailViewWrapper';

// Placeholder
const TitleDetail = () => {
  const { id } = useParams();

  console.log('titleId: ', id); // for testing purpose

  return (
    <TitleDetailViewWrapper dateTestId="title-detail-view" title={mockSamAsset.title.titleName} subTitle={mockSamAsset.title.titleType}>
      <TitleDetailMain />
    </TitleDetailViewWrapper>
  );
};

export default TitleDetail;

import { SAMAsset } from '@amzn/ce-browse-models';
import { GridRowSelectionModel } from '@mui/x-data-grid-premium';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import pathManifest from '../../constants/pathManifest';
import { AssetsTableColumn } from '../../enums/AssetsTableColumn';
import useGetTitleMetadata from '../../hooks/useGetTitleMetadataQuery';
import { ItemType } from '../../types';
import PreviewDrawer from '../common/preview/PreviewDrawer';
import Table from '../common/table/Table';
import TableActionBar from '../common/table/TableActionBar';
import AssetsPreview from './AssetsPreview';
import { columns } from './table/AssetsTableColumns';

type Props = {
  data: Array<SAMAsset>;
};

// Placeholder
const AssetsMain = ({ data }: Props) => {
  const [selectedItem, setSelectedItem] = useState<ItemType>();
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const navigate = useNavigate();

  // Added this for lambda authorizer testing purpose
  const { data: titleData } = useGetTitleMetadata('amzn1.studios.title.02507928-0006-45a9-a256-c73bb2ce18d3');

  const handleClosePreview = () => {
    setSelectedItem(undefined);
  };

  const onRowSelectionModelChange = (selection: GridRowSelectionModel) => {
    setRowSelectionModel(selection);
  };

  const handleClearSelection = () => {
    setRowSelectionModel([]);
  };

  console.log(titleData);

  return (
    <>
      {rowSelectionModel?.length > 0 && (
        <TableActionBar selectedCount={rowSelectionModel.length} onClearSelection={handleClearSelection} isEditable />
      )}

      <Table
        onRowSelectionModelChange={onRowSelectionModelChange}
        currentSelection={rowSelectionModel}
        data={data}
        columns={columns}
        setSelectedItem={setSelectedItem}
        onRowDoubleClick={({ row }) => navigate(`${pathManifest.assetLibrary.assets.index}/${row?.amazonStudiosAssetId as string}`)}
        onCellClick={({ field }, event) => {
          // to prevent event bubbling on row click in certain fields
          if (
            [AssetsTableColumn.ModifiedBy, AssetsTableColumn.CreatedBy, AssetsTableColumn.DirectPath].includes(field as AssetsTableColumn)
          ) {
            event.stopPropagation();
          }
        }}
      />

      <PreviewDrawer open={!!selectedItem} onClose={handleClosePreview} width="35rem">
        <AssetsPreview onClose={handleClosePreview} />
      </PreviewDrawer>
    </>
  );
};

export default AssetsMain;

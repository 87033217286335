const pathManifest = {
  home: '/asset-library',
  assetLibrary: {
    index: '/asset-library',
    titles: {
      index: '/asset-library/titles',
      detail: '/asset-library/titles/:id',
    },
    assets: {
      index: '/asset-library/assets',
      detail: '/asset-library/assets/:id',
    },
    unmatched: '/asset-library/unmatched',
  },
  archival: {
    index: '/archival',
    archival: '/archival/archival',
    myItems: '/archival/my-items',
  },
  admin: {
    index: '/admin',
    ingest: {
      index: '/admin/ingest',
      detail: '/admin/ingest/:id',
      edit: '/admin/ingest/:id/edit',
      create: '/admin/ingest/new',
    },
    distribution: {
      index: '/admin/distribution',
      detail: '/admin/distribution/:id',
      edit: '/admin/distribution/:id/edit',
      create: '/admin/distribution/new',
    },
    assetSpecs: { index: '/admin/asset-specs' },
  },
};

export default pathManifest;

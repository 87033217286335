import Link from '@mui/material/Link';
import React from 'react';

export const phoneToolUrl = 'https://phonetool.amazon.com/users/';

type Props = {
  alias?: string;
};

const PhoneToolLink = ({ alias }: Props) => {
  if (!alias) {
    return null;
  }

  return (
    <Link target="_blank" role="link" underline="none" href={`${phoneToolUrl}${alias}`}>
      {alias}
    </Link>
  );
};

export default PhoneToolLink;

import { mockSamAssets } from '@amzn/ce-browse-models';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tab } from '../../enums/Tab';
import ViewWrapper from '../common/ViewWrapper';
import AssetsMain from './AssetsMain';

const Assets = () => {
  const { t } = useTranslation();

  const data = mockSamAssets;

  return (
    <ViewWrapper
      title={t('Assets')}
      dateTestId="assets-view"
      tab={Tab.AssetLibrary}
      hasExportCsv
      hasSearchBar
      hasFilter
      hasIngestBtn
      hasTotalCount
      totalCount={data.length}>
      <AssetsMain data={data} />
    </ViewWrapper>
  );
};

export default Assets;

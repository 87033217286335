import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  handleOpen?: () => void;
  handleDownload?: () => void;
  handleShare?: () => void;
  handleMore?: () => void;
  handleEdit?: () => void;
};

const DetailViewBtns = ({ handleMore, handleOpen, handleDownload, handleShare, handleEdit }: Props) => {
  const { t } = useTranslation();

  return (
    <Stack justifyContent="space-between" direction="row" spacing={2}>
      <IconButton onClick={handleDownload}>
        <DownloadOutlinedIcon />
      </IconButton>
      <IconButton onClick={handleEdit}>
        <EditIcon />
      </IconButton>
      <IconButton onClick={handleShare}>
        <SendIcon />
      </IconButton>

      <Button variant="contained" onClick={handleOpen} startIcon={<OpenInNewIcon />}>
        {t('Media Player')}
      </Button>

      <IconButton onClick={handleMore}>
        <MoreVertOutlinedIcon />
      </IconButton>
    </Stack>
  );
};

export default DetailViewBtns;

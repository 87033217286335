import { gql } from '@apollo/client';

// Placeholder for now. Should be done by https://originalsaccess.atlassian.net/browse/MSC-1837
const getTitleMetadata = gql`
  query GetTitleMetadata($input: GetProxyTitleMetadataInput!) {
    getTitleMetadata(input: $input) {
      titleId
      titleName
      titleType
      showCode
      parentTitleName
      childTitleName
      securityTier
      countryOfOrigin
      lifecyclePhase
      placeholderFlag
      productionFormat
      greenlightStatus
      seasonNumber
      seasonPart
      episodesOrdered
      episodesProduced
      episodeProductionNumber
      episodeSequenceNumber
      productionCompany
      productionCountry
      releaseCadence
      releaseDateRange
      releaseLanguages
      releasePlatform
      releaseStatus
      excludedReleaseTerritory
      releasingEntity
      originalAudio
      originalFormat
    }
  }
`;

export default getTitleMetadata;

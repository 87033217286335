import { GridColDef } from '@mui/x-data-grid-premium';
import { TFunction } from 'react-i18next';

const titlesTableColumns = (intl: TFunction<'translation', undefined>): Array<GridColDef> => [
  {
    field: 'showCode',
    renderHeader: () => intl('Show Code'),
  },
  {
    field: 'titleName',
    renderHeader: () => intl('Enterprise Title Name'),
    minWidth: 300,
  },
  {
    field: 'titleType',
    renderHeader: () => intl('Title Type'),
  },
  {
    field: 'seasonNumber',
    renderHeader: () => intl('Season Number'),
  },
  {
    field: 'seasonPart',
    renderHeader: () => intl('Season Part'),
  },
  {
    field: 'brandingType',
    renderHeader: () => intl('Branding Type'),
  },
  {
    field: 'greenlightStatus',
    renderHeader: () => intl('Greenlight Status'),
  },
  {
    field: 'targetReleaseDate',
    renderHeader: () => intl('Target Release Date - Date'),
  },
  {
    field: 'releaseDateRange',
    renderHeader: () => intl('Release Date'),
  },
  {
    field: 'releaseStatus',
    renderHeader: () => intl('Release Status'),
    minWidth: 200,
  },
  {
    field: 'excludedReleaseTerritory',
    renderHeader: () => intl('Release Territory'),
  },
  {
    field: 'scriptType',
    renderHeader: () => intl('Scripted / Unscripted / Live'),
  },
  {
    field: 'ownershipType',
    renderHeader: () => intl('Ownership Type'),
  },
  {
    field: 'originatingTerritory',
    renderHeader: () => intl('Originating Territory'),
  },
  {
    field: 'lifecyclePhase',
    renderHeader: () => intl('Lifecycle Phase'),
    minWidth: 300,
  },
];

const baseColumnProps = {
  editable: false,
  resizable: false,
  flex: 0,
  minWidth: 150,
};

export const columns = (intl: TFunction<'translation', undefined>): Array<GridColDef> =>
  titlesTableColumns(intl).map((column) => ({ ...baseColumnProps, ...column }));

export const badgePhotoUrl = 'https://internal-cdn.amazon.com/badgephotos.amazon.com/?give404ifmissing=true&uid=';

const getAmazonEmail = (alias: string) => `${alias}@amazon.com`;

const getAmazonId = (email: string) => {
  if (email.endsWith('@amazon.com')) {
    return email.split('@')[0];
  }

  return '';
};

const getPhotoUrl = (email: string) => `${badgePhotoUrl}${getAmazonId(email)}`;

const getPhotoUrlFromAlias = (alias: string) => `${badgePhotoUrl}${alias}`;

export default {
  getAmazonEmail,
  getAmazonId,
  getPhotoUrl,
  getPhotoUrlFromAlias,
};

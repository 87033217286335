import { SAMAsset } from '@amzn/ce-browse-models';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import AssetOrderStatus from '../common/AssetOrderStatus';

type Props = {
  data: SAMAsset;
};

// Placeholder
const AssetDetailMetadata = ({ data }: Props) => {
  const { t } = useTranslation();

  const metadata = [
    {
      field: t('Version'),
      value: <Chip label={data.workOrder.workOrderVersion} size="small" />,
    },
    {
      field: t('Status'),
      value: <AssetOrderStatus status={data.assetStatus} />,
    },
    {
      field: t('Territory'),
      value: 'US', // need to check this value
    },
    {
      field: t('Language'),
      value: data.languageLocale,
    },
    {
      field: t('Due Date'),
      value: data.workOrder.dueDate,
    },
  ];

  const getMetadataComponent = () => {
    const finalComponent: Array<ReactNode> = [];
    metadata.forEach((item, index) => {
      finalComponent.push(
        <Stack key={item.field} alignItems="center" spacing={1}>
          <Typography variant="caption">{item.field}</Typography>
          <Typography variant="subtitle2">{item.value}</Typography>
        </Stack>
      );

      if (index !== metadata.length - 1) {
        // eslint-disable-next-line react/no-array-index-key
        finalComponent.push(<Divider key={index} orientation="vertical" sx={{ height: 'auto' }} />);
      }
    });

    return finalComponent;
  };

  return (
    <Paper elevation={2}>
      <Stack direction="row" justifyContent="space-evenly" py={3}>
        {getMetadataComponent()}
      </Stack>
    </Paper>
  );
};

export default AssetDetailMetadata;

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import React from 'react';

import { greyText } from '../../../context/Theme';
import { ButtonSize } from '../../../enums/ButtonSize';

type Props = {
  size?: ButtonSize;
  onClose: () => void;
};

const CloseBtn = ({ size = ButtonSize.Medium, onClose }: Props) => (
  <IconButton onClick={onClose} sx={{ color: greyText }}>
    <CloseIcon fontSize={size} />
  </IconButton>
);

export default CloseBtn;

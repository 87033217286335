import { CommonUserPreferences } from '@amzn/sitc-frontend/types';
import noop from 'lodash/noop';
import React, { useEffect, useMemo, useState } from 'react';

import { getLocalStorageItemWithExpiry, setLocalStorageItemWithExpiry } from '../utils/localStorageWithExpiry';

export const samPrefix = 'SAM_PREFERENCES';

interface ThemeModeProviderProps {
  children: React.ReactNode;
  themePreference: CommonUserPreferences['theme'];
}

export const ThemeModeContext = React.createContext<{
  themeMode: string;
  setThemeMode: (themeMode: string) => void;
}>({
  themeMode: '',
  setThemeMode: noop,
});

export const ThemeModeProvider: React.FC<ThemeModeProviderProps> = ({ children, themePreference }) => {
  const [themeMode, setThemeMode] = useState<string>(themePreference.name);

  useEffect(() => {
    const preferenceData = getLocalStorageItemWithExpiry(samPrefix);

    if (preferenceData) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setThemeMode(JSON.parse(preferenceData)?.value?.mode);
    }
  }, []);

  useEffect(() => {
    setLocalStorageItemWithExpiry(samPrefix, JSON.stringify({ value: { mode: themeMode } }));
  }, [themeMode]);

  const contextValue = useMemo(() => ({ themeMode, setThemeMode }), [themeMode]);

  return <ThemeModeContext.Provider value={contextValue}>{children}</ThemeModeContext.Provider>;
};

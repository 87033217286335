import { UtilityItem } from '@amzn/sitc-frontend/types';
import { searchEmployees } from '@amzn/talent-search-client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import badgePhoto from '../../utils/badgePhoto';
import { SearchEmployeesFilterOptions } from '../leadershipIds';

const HeaderProfileMenu = (alias: string): UtilityItem => {
  const { t } = useTranslation();
  const [name, setName] = useState<string>('');

  useEffect(() => {
    const getFullname = async () => {
      await searchEmployees({
        searchQuery: alias,
        options: { filterOptions: [...SearchEmployeesFilterOptions] },
      }).then((data) => {
        if (data) {
          const index = data?.findIndex((item) => item?.username === alias);
          if (index > -1) {
            setName(data?.[index]?.fullName);
          }
        }
      });
    };

    // eslint-disable-next-line no-void
    void getFullname();
  }, [alias]);

  return {
    key: 'profile',
    primary: t('sam.header.profile'),
    iconKey: 'AccountCircleOutlined',
    toolTip: t('sam.header.profile'),
    navContents: [
      {
        key: 'account-details',
        navItems: [
          {
            avatarSource: badgePhoto.getPhotoUrlFromAlias(alias),
            primary: name,
            secondary: badgePhoto.getAmazonEmail(alias),
          },
        ],
      },
    ],
  };
};

export default HeaderProfileMenu;

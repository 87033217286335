import { mockSamAssets } from '@amzn/ce-browse-models';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tab } from '../../enums/Tab';
import ViewWrapper from '../common/ViewWrapper';
import UnmatchedMain from './UnmatchedMain';

const Unmatched = () => {
  const { t } = useTranslation();

  const data = mockSamAssets;

  return (
    <ViewWrapper
      dateTestId="unmatched-view"
      tab={Tab.AssetLibrary}
      title={t('Unmatched Queue')}
      hasExportCsv
      hasSearchBar
      hasFilter
      hasTotalCount>
      <UnmatchedMain data={data} />
    </ViewWrapper>
  );
};

export default Unmatched;

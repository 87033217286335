import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tab } from '../../enums/Tab';
import ViewWrapper from '../common/ViewWrapper';
import DistributionProfilesMain from './DistributionProfilesMain';

const DistributionProfiles = () => {
  const { t } = useTranslation();

  return (
    <ViewWrapper title={t('Distribution Profiles')} dateTestId="distribution-profile-view" tab={Tab.Admin} hasExportCsv>
      <DistributionProfilesMain />
    </ViewWrapper>
  );
};

export default DistributionProfiles;

export enum ArchivalList {
  Archival = 'Archival',
  MyItems = 'My Items',
}

export enum AssetLibraryList {
  Titles = 'Titles',
  Assets = 'Assets',
  Unmatched = 'Unmatched',
}

export enum AdminList {
  Ingest = 'Ingest Profiles',
  Distribution = 'Distribution Profiles',
  AssetSpecs = 'Asset Specs',
}

export enum Tab {
  AssetLibrary = 'AssetLibrary',
  Archival = 'Archival',
  Admin = 'Admin',
}

import { NavTreeItem } from '@amzn/sitc-frontend/components';
import TreeView from '@mui/lab/TreeView';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import React from 'react';

import { AppBarHeight } from '../../constants/app';
import { TreeDrawerWidth } from '../../constants/drawer';

// Data structure for title tree is not finalized
const TitleTree = () => (
  <Drawer anchor="left" sx={{ width: TreeDrawerWidth }} variant="permanent">
    <Box sx={{ width: TreeDrawerWidth, marginTop: `${AppBarHeight}px`, padding: 1 }}>
      <TreeView sx={{ height: '100%', flexGrow: 1, overflowY: 'auto', width: '100%' }}>
        <NavTreeItem nodeId="1" label="Lizzo’s Watch Out For The Big Grrrls" data-testid="title-tree">
          <NavTreeItem nodeId="2" label="Season 1: Here you go">
            <NavTreeItem nodeId="3" label="E101: Becoming 100% That Bitch" />
            <NavTreeItem nodeId="3" label="E102: HBCYOU band" />
          </NavTreeItem>
          <NavTreeItem nodeId="2" label="Season 2: Title">
            <NavTreeItem nodeId="3" label="Episode 1" />
          </NavTreeItem>
        </NavTreeItem>
      </TreeView>
    </Box>
  </Drawer>
);

export default TitleTree;

import { PageLoading } from '@amzn/sitc-frontend/components';
import { AuthSession } from '@amzn/sitc-frontend/contexts';
import { ApolloClient, ApolloProvider as ApolloClientProvider, NormalizedCacheObject } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';

import { configureApolloClient } from '../config/config';
import { Stage } from '../enums/Stage';
import { MicroAppConfigContext } from '../utils/app';

interface ApolloProviderProps {
  authSession: AuthSession;
  stage: Stage;
  children?: React.ReactNode;
}

export const ApolloProvider: React.FC<ApolloProviderProps> = ({ stage, children, authSession }) => {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject> | null>(null);
  const { appId } = useContext(MicroAppConfigContext);

  useEffect(() => {
    try {
      const apolloClient = configureApolloClient(stage, authSession, appId);
      setClient(apolloClient);
    } catch (error: unknown) {
      if (typeof error === 'string') {
        console.error(error.toUpperCase());
      } else if (error instanceof Error) {
        console.error(error.message);
      }
    }
  }, [authSession, stage]);

  if (!client) {
    return <PageLoading />;
  }

  return <ApolloClientProvider client={client}>{children}</ApolloClientProvider>;
};

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';

import useStatusStyles from '../../styles/status';

type Props = {
  status?: string;
};

const Status = ({ status }: Props) => {
  const classes = useStatusStyles;

  return (
    <Box data-testid={status} sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
      <Box alignItems="center" display="flex">
        {status && <Box sx={{ ...classes.dot, ...classes[status.toLowerCase()] }} />}
        <Typography color="inherit" variant="body2">
          {status}
        </Typography>
      </Box>
    </Box>
  );
};

export default Status;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tab } from '../../enums/Tab';
import ViewWrapper from '../common/ViewWrapper';
import AssetSpecsMain from './AssetSpecsMain';

const AssetSpecs = () => {
  const { t } = useTranslation();

  return (
    <ViewWrapper title={t('Asset Specs')} dateTestId="asset-specs-view" tab={Tab.Admin} hasExportCsv>
      <AssetSpecsMain />
    </ViewWrapper>
  );
};

export default AssetSpecs;

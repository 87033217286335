import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  totalCount?: number;
  text?: string;
};

const TotalCount = ({ totalCount = 0, text = 'assets' }: Props) => {
  const { t } = useTranslation();

  return <Typography color="text.secondary">{`${totalCount.toLocaleString()} ${t(text, { count: totalCount })}`}</Typography>;
};

export default TotalCount;
